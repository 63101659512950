import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Tnc = props => {
  return (
    <Layout location={props.location} title="OPTOTAX - Privacy Policy">
      <SEO title="Taxation Courses" />
      <div className="banner-body">
        <div className="banner-content">
          <div className="py-2 px-5 tnc">
            <h2 className="mt-5 font-extrabold">Privacy Policy</h2>
            <h6>Last updated: May 13th, 2020</h6>
            <h4 className="mt-5 font-extrabold">OVERVIEW</h4>
            <p>
              Please read this Privacy Policy document carefully before using:
              <a href="https://gst.optotax.com" target="_blank">
                {" "}
                https://gst.optotax.com (“OPTOTAX”)
              </a>{" "}
              and/ or
              <a href="https://app.readyb2b.com" target="_blank">
                {" "}
                https://app.readyb2b.com ("READYB2B")
              </a>{" "}
              and/ or RupeeMinder App (“RUPEEMINDER”) operated by OPTOBIZZ
              SOFTWARE Private Limited ("OPTOBIZZ", "us", "we", or "our").
            </p>
            <p>
              This page informs you of our policies regarding the collection,
              use, and disclosure of personal/ business data when you use
              “OPTOTAX” and/ or “READYB2B” and/ or “RUPEEMINDER” and the choices
              you have associated with that data.
            </p>
            <p>
              By using “OPTOTAX” and/ or “READYB2B” and/ or “RUPEEMINDER”, you
              agree to the collection and use of information in accordance with
              this policy. Unless otherwise defined in this Privacy Policy,
              terms used in this Privacy Policy have the same meanings as in our
              Terms and Conditions.
            </p>

            <h5 className="mt-5 uppercase">
              1. Information Collection and Use
            </h5>
            <p>
              "OPTOBIZZ" collects several different types of information for
              various purposes to provide and improve “OPTOTAX” and/ or
              “READYB2B” and/ or “RUPEEMINDER” and other "OPTOBIZZ" offerings to
              our customers from time to time.
            </p>

            <h5 className="mt-5 uppercase">
              2. Types of Data Collected ("Data")
            </h5>

            <p>
              <ol>
                <li>
                  <h5>Personal Data</h5>

                  <p>
                    While using “OPTOTAX” and/ or “READYB2B” and/ or
                    “RUPEEMINDER”, we may ask you to provide us with certain
                    personally identifiable information that can be used to
                    contact or identify you ("Personal Data"). This information
                    may include, but is not limited to:
                  </p>
                  <p>
                    <ul>
                      <li>First name and last name</li>
                      <li>Phone number(s)</li>
                      <li>Email address(es)</li>
                      <li>Profession</li>
                      <li>Address, City, State, Postal pin code, Country</li>
                      <li>PAN card number</li>
                      <li>Cookies and Usage Data</li>
                    </ul>
                  </p>
                </li>

                <li>
                  <h5>Business Data</h5>

                  <p>
                    While using “OPTOTAX” and/ or “READYB2B” and/ or
                    “RUPEEMINDER”, we may ask you to provide us with certain
                    identifiable information that can be used to contact or
                    identify your business ("Business Data"). This information
                    may include, but is not limited to
                  </p>
                  <p>
                    <ul>
                      <li>Business Name</li>
                      <li>Contact person Name</li>
                      <li>Business phone</li>
                      <li>Business email</li>
                      <li>GSTIN/ PAN number</li>
                      <li>GSTN Username</li>
                      <li>
                        Business Address, City, State, Postal pin code, Country
                      </li>
                      <li>
                        Information pertaining to Goods and/ or Services that
                        are generally purchased and/ or sold by the Business,
                        e.g. HSN code and Description, Tax rates, Item Name,
                        Unit, Purchase and Sale price, Discounts offered, etc.
                      </li>
                      <li>
                        Information pertaining to Businesses' suppliers and
                        customers with whom the Business conducts day-today
                        transactions, e.g. Business Name, Contact Person, Phone,
                        Email, GSTIN, GST Registration Type and Business
                        Address, City, State, Postal pin code, Country
                      </li>
                      <li>
                        Information pertaining to Businesses Sales and or
                        Purchase transactions be it from and/ or to the
                        suppliers and/ or customers mentioned above or to
                        Unregistered taxpayers (as defined by GST Laws).
                      </li>
                      <li>
                        GST returns data submitted by the business to the GSTN
                        including but not limited to Gross Turn-over,
                        month-on-month returns data filed for Purchases and
                        Sales of various Goods and/ or Services.
                      </li>
                    </ul>
                  </p>
                </li>

                <li>
                  <h5>Usage Data</h5>

                  <ul>
                    <li>
                      {" "}
                      We may also collect information that your browser sends
                      whenever you visit “OPTOTAX” and/ or “READYB2B” and/ or
                      “RUPEEMINDER” or when you access “OPTOTAX” and/ or
                      “READYB2B” and/ or “RUPEEMINDER” by or through a mobile
                      device ("Usage Data").
                    </li>
                    <li>
                      This Usage Data may include information such as your
                      computer's Internet Protocol address (e.g. IP address),
                      browser type, browser version, the pages of “OPTOTAX” and/
                      or “READYB2B” and/ or “RUPEEMINDER” that you visit, the
                      time and date of your visit, the time spent on those
                      pages, unique device identifiers and other diagnostic
                      data.
                    </li>
                    <li>
                      When you access “OPTOTAX” and/ or “READYB2B” and/ or
                      “RUPEEMINDER” by or through a mobile device, this Usage
                      Data may include information such as the type of mobile
                      device you use, your mobile device unique ID, the IP
                      address of your mobile device, your mobile operating
                      system, the type of mobile Internet browser you use,
                      unique device identifiers and other diagnostic data.
                    </li>
                  </ul>
                </li>

                <li>
                  <h5>Tracking Cookies Data</h5>

                  <p>
                    We use cookies and similar tracking technologies to track
                    the activity on “OPTOTAX” and/ or “READYB2B” and/ or
                    “RUPEEMINDER” and hold certain information.
                  </p>
                  <p>
                    Cookies are files with small amount of data which may
                    include an anonymous unique identifier. Cookies are sent to
                    your browser from a website and stored on your device.
                    Tracking technologies also used are beacons, tags, and/ or
                    scripts to collect and track information and to improve and
                    analyze “OPTOTAX” and/ or “READYB2B”.
                  </p>
                  <p>
                    You can instruct your browser to refuse all cookies or to
                    indicate when a cookie is being sent. However, if you do not
                    accept cookies, you may not be able to use some portions of
                    “OPTOTAX” and/ or “READYB2B” and/ or “RUPEEMINDER”.
                  </p>

                  <p>Examples of Cookies we use:</p>
                  <p>
                    <ul>
                      <li>
                        Session Cookies. We use Session Cookies to operate
                        “OPTOTAX” and/ or “READYB2B” and/ or “RUPEEMINDER”.
                      </li>
                      <li>
                        Preference Cookies. We use Preference Cookies to
                        remember your preferences and various settings.
                      </li>
                      <li>
                        Security Cookies. We use Security Cookies for security
                        purposes.
                      </li>
                    </ul>
                  </p>
                </li>
                <li>
                  Under no circumstances will "OPTOBIZZ" be liable for any
                  expense, loss or damage including, without limitation,
                  indirect or consequential loss or damage, or any expense, loss
                  or damage whatsoever arising from use, or loss of use, of
                  data, arising out of or in connection with the use of
                  "OPTOTAX.".
                </li>
                <li>
                  "OPTOBIZZ" does not make any claim that access to “OPTOTAX”
                  and/ or “READYB2B” and/ or “RUPEEMINDER” will be
                  uninterrupted, complete or error-free.
                </li>

                <li>
                  "OPTOBIZZ" will not be held liable for Users inability or
                  failure to perform legal or other research-related work or to
                  perform such legal or other research or related work properly
                  or completely, even if assisted by “OPTOTAX” and/ or
                  “READYB2B” and/ or “RUPEEMINDER”, its Affiliates and/or
                  licensors of the foregoing or any decision made or action
                  taken by User in reliance on “OPTOTAX” and/ or “READYB2B” and/
                  or “RUPEEMINDER”.
                </li>
                <li>
                  "OPTOBIZZ" shall not be liable for the use of “OPTOTAX” and/
                  or “READYB2B” and/ or “RUPEEMINDER” by User. And the User
                  shall hold "OPTOBIZZ" harmless and fully indemnified against
                  any claims, costs, damages, loss and liabilities arising out
                  of any such use.
                </li>
                <li>
                  Nothing in this Agreement confers or purports to confer on any
                  third-party any benefit or any right to enforce any term of
                  this Agreement.
                </li>
              </ol>
            </p>
            <h5 className="mt-5 uppercase">3. Use of Data</h5>
            <p>
              "OPTOBIZZ", at its discretion, may use the collected "Data" for
              various purposes:
              <p>
                <ul>
                  <li>
                    To provide and maintain “OPTOTAX” and/ or “READYB2B” and/ or
                    “RUPEEMINDER” and/ or other offerings of "OPTOBIZZ" whether
                    current or prospective products to be developed sometime in
                    the future course
                  </li>
                  <li>
                    To notify you about changes to “OPTOTAX” and/ or “READYB2B”
                    and/ or “RUPEEMINDER” or "OPTOBIZZ"
                  </li>
                  <li>
                    To allow you to participate in interactive features of
                    “OPTOTAX” and/ or “READYB2B” and/ or “RUPEEMINDER” when you
                    choose to do so
                  </li>
                  <li>To provide customer care and support</li>
                  <li>
                    To provide analysis or valuable information so that we can
                    improve “OPTOTAX” and/ or “READYB2B” and/ or “RUPEEMINDER”
                  </li>
                  <li>
                    To monitor the usage of “OPTOTAX” and/ or “READYB2B” and/ or
                    “RUPEEMINDER”
                  </li>
                  <li>To detect, prevent and address technical issues</li>
                  <li>
                    Work with "OPTOBIZZ" authorized and scrutinized third-party
                    providers on prospective product ideas for commercial or
                    non-commercial purposes
                  </li>
                  <li>
                    We may from time to time contact you via email, facsimile,
                    phone or text message, to deliver certain services or
                    information you have requested
                  </li>
                </ul>
              </p>
            </p>

            <h5 className="mt-5 uppercase">4. Transfer of Data</h5>
            <p>
              <ul>
                <li>
                  Your information, including Personal Data, may be transferred
                  to - and maintained on - computers located outside of your
                  state, province, country or other governmental jurisdiction
                  where the data protection laws may differ than those from your
                  jurisdiction.
                </li>
                <li>
                  If you are located outside India and choose to provide
                  information to us, please note that we transfer the data,
                  including Personal Data, to India and process it there.
                </li>
                <li>
                  Your consent to this Privacy Policy followed by your
                  submission of such information represents your agreement to
                  that transfer.
                </li>
                <li>
                  “OPTOTAX” and/ or “READYB2B” and/ or “RUPEEMINDER” will take
                  all steps reasonably necessary to ensure that your data is
                  treated securely and in accordance with this Privacy Policy
                  and no transfer of your Personal Data will take place to an
                  organization or a country unless there are adequate controls
                  in place including the security of your data and other
                  personal information.
                </li>
                <li>
                  “OPTOTAX” and/ or “READYB2B” and/ or “RUPEEMINDER” will take
                  all steps reasonably necessary to ensure that data is treated
                  securely, and no personal data is shared without prior consent
                  of the parties involved, unless it is a legal obligation.
                  “OPTOTAX” and/ or “READYB2B” and/ or “RUPEEMINDER” reserves
                  the right to share masked data with third parties if need be,
                  for commercial or non-commercial purposes.
                </li>
              </ul>
            </p>

            <h5 className="mt-5 uppercase">5. Disclosure of Data</h5>

            <p>
              "OPTOBIZZ" may disclose your "Data" in the good faith belief that
              such action is necessary to:
              <ul>
                <li>To comply with a legal obligation</li>
                <li>
                  To protect and defend the rights or property of “OPTOTAX” and/
                  or “READYB2B” and/ or “RUPEEMINDER”
                </li>
                <li>
                  To prevent or investigate possible wrongdoing in connection
                  with “OPTOTAX” and/ or “READYB2B” and/ or “RUPEEMINDER”
                </li>
                <li>
                  To protect the personal safety of users of “OPTOTAX” and/ or
                  “READYB2B” and/ or “RUPEEMINDER” or the public
                </li>
                <li>To protect against legal liability</li>
              </ul>
            </p>

            <h5 className="mt-5 uppercase">6. Security of Data</h5>
            <p>
              The security of your "Data" is important to us but remember that
              no method of transmission over the Internet, or method of
              electronic storage is 100% secure. While we strive to use
              commercially acceptable means to protect your Personal Data, we
              cannot guarantee its absolute security. Please do therefore refer
              to the "Disclaimer of Warranties and Limitation of Liability"
              clause from our Terms and Conditions.
            </p>

            <h5 className="mt-5 uppercase">7. Service Providers</h5>
            <p>
              We may employ third party companies and individuals to facilitate
              “OPTOTAX” and/ or “READYB2B” and/ or “RUPEEMINDER” ("Service
              Providers"), to provide “OPTOTAX” and/ or “READYB2B” on our
              behalf, to perform Service­ related services or to assist us in
              analyzing how “OPTOTAX” and/ or “READYB2B” and/ or “RUPEEMINDER”
              is used.
            </p>
            <p>
              These third parties have access to your "Data" only to perform
              these tasks on our behalf and are obligated not to disclose or use
              it for any purpose not specifically authorized by "OPTOBIZZ" or
              “OPTOTAX” and/ or “READYB2B” and/ or “RUPEEMINDER” in writing.
            </p>

            <h5 className="mt-5 uppercase">8. Children's Privacy</h5>
            <p>
              “OPTOTAX” and/ or “READYB2B” and/ or “RUPEEMINDER” does not
              address anyone under the age of 18 ("Children").
            </p>
            <p>
              We do not knowingly collect personally identifiable information
              from anyone under the age of 18. If you are a parent or guardian
              and you are aware that your Child/ Children has/ have provided us
              with Personal Data, please contact us immediately. If we become
              aware that we have collected Personal Data from children with or
              without verification of parental consent, we take steps to remove
              that information from our servers.
            </p>
            <h5 className="mt-5 uppercase">
              9. Changes to This Privacy Policy
            </h5>
            <p>
              We reserve the right, at our sole discretion, to modify or replace
              this "Privacy Policy" at any time or in any manner deemed
              necessary for "OPTOBIZZ" to conduct its day-to-day business. Users
              are therefore cautioned to frequently look at our "Privacy Policy"
              to update themselves of any changes that "OPTOBIZZ" or “OPTOTAX”
              and/ or “READYB2B” and/ or “RUPEEMINDER” has incorporated.
              "OPTOBIZZ" will strive to provide updates of this nature over
              email but our inability to send such email(s) for any reason
              cannot be construed against us.
            </p>
            <p>
              By continuing to access or use “OPTOTAX” and/ or “READYB2B” and/
              or “RUPEEMINDER”, you always agree to be bound by the “Privacy
              Policy”. If you do not agree to the "Privacy Policy", please stop
              using “OPTOTAX” and/ or “READYB2B” and/ or “RUPEEMINDER” with
              immediate effect.
            </p>
            <h5 className="mt-5 uppercase">10. Contact Us</h5>
            <p>
              If you have any questions about this Terms and Conditions, please
              contact us:
              <ul>
                <li>
                  By email:{" "}
                  <a href="mailto:policies@optobizz.com">
                    policies@optobizz.com
                  </a>
                </li>
                <li>
                  By visiting our support portal:{" "}
                  <a href="https://support.readyb2b.com">
                    https://support.readyb2b.com
                  </a>
                </li>
              </ul>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default Tnc
